.sidebar-button {
    align-self: center;
    text-align: center;
}

.sidebar-icon {
    margin: 500px;
    background-color: black;
    color: brown;
}

.list {
    align-content: center;
    display: table;
    align-Items: 'center';
    justify-Content: 'center';
    align-self: center;

    
}