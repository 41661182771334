.scheduler-grid {
    /*flex-grow: 1;*/
    
    /*width: max-content*/
    /*overflow: scroll;*/
    height: 100%;
    
    /* overflow-y:scroll;
    overflow-x:scroll; */
    
}
.channel-header {
    background-color: rgb(179, 179, 179);
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 0;
    /*position: fixed;*/
    width: 535px;

    position: sticky; top: 20; z-index: 1;
}

.spacer {
    /*height: 40px;*/
}

.paper {
    
}

.box:nth-child(odd) {
    background-color: brown;
}

.box:nth-child(even) {
    background-color:cornflowerblue;
}